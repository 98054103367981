import React from 'react';
import classes from './styles.module.scss';
import Layout from '../../components/Layout';
import ErrorImg from '@/public/assets/404.png';
import SKButton from '../../components/UI/SKButton';
import { HOME_PAGE } from '../../constants/route.const';
import { Box, Heading, Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';

export default function Error404() {
  const router = useRouter();
  return (
    <Layout>
      <Box className={classes.PageNotFound} mt={8}>
        <Image
          src={ErrorImg.src}
          alt="Page not found"
          width={['200px', null, null, '400px']}
        />
        <Heading mt={18}>Page Not Found</Heading>
        <Heading as="h3" fontSize={'20px'} fontWeight={400}>
          The Page you are looking for cannot be found.
        </Heading>
        <SKButton onClick={() => router.push(HOME_PAGE)} color="white">
          Back to Homepage
        </SKButton>
      </Box>
    </Layout>
  );
}
